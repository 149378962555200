<template>
  <ul class="menu-nav">
    <router-link to="/AdminDashboard" v-slot="{ href, navigate, isActive, isExactActive }" v-if="!isCustomerUser">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{ $t("leftMenu.home") }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/Customer/CustomerDashboard" v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="isCustomerUser">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">{{ $t("leftMenu.home") }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/Users/Users" v-slot="{ href, navigate, isActive, isExactActive }" v-if="!isCustomerUser">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">{{ $t("leftMenu.users") }}</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="!isCustomerUser">
      <h4 class="menu-text">Subscription</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/Subscription') }" v-if="!isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Subscriptions</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Subscriptions</span>
            </span>
          </li>

          <router-link to="/registerationManager" v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="!isCustomerUser">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.subscriptionRequests") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Subscription/SubscriptionPackage" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.subscriptionList.manageSubscriptionPackage") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Subscription/Subscription" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.subscriptionList.manageSubscriptions") }}</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('Company Structure') }" v-if="!isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Company Structure</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu" style="display: none; overflow: hidden;">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Company Structure</span>
            </span>
          </li>

          <router-link to="/EconomicSector" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("leftMenu.structureLookupList.economicSector")
                }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/IndustryType" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("leftMenu.structureLookupList.industryType")
                }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/BusinessSector" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("leftMenu.structureLookupList.businessSector")
                }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/BusinessSubSector" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("leftMenu.structureLookupList.businessSubSector")
                }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/JobFamily" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("leftMenu.structureLookupList.jobFamily")
                }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Job" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("leftMenu.structureLookupList.jobTitle")
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- <router-link to="/LegalStructure" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.structureLookupList.setupList.legalStructureLookup") }}</span>
              </a>
            </li>
          </router-link> -->


        </ul>
      </div>
    </li>

    <li class="menu-section" v-if="!isCustomerUser">
      <h4 class="menu-text">Setup</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/SurveyYear') }" v-if="!isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-list-2"></i>
        <span class="menu-text">Setup</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Setup</span>
            </span>
          </li>

          <router-link to="/SurveyYear" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.structureLookupList.setupList.surveyYear") }}</span>
              </a>
            </li>
          </router-link>


        </ul>
      </div>
    </li>

    <li class="menu-section" v-if="!isCustomerUser">
      <h4 class="menu-text">Market Analysis</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/MarketAnalysis') }" v-if="!isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-list-2"></i>
        <span class="menu-text">Market Analysis</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Market Analysis</span>
            </span>
          </li>

          <router-link to="/MarketAnalysis/MarketInsights" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.marketAnalysisList.marketInsights") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/MarketAnalysis/MarketPayTrends" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.marketAnalysisList.marketPayTrends") }}</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>



    <li class="menu-section" v-if="!isCustomerUser">
      <h4 class="menu-text">Customer</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/Customer') }" v-if="!isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-user-1"></i>
        <span class="menu-text">Customer</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Customer</span>
            </span>
          </li>

          <router-link to="/Customer/Profile" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.profile") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Customer/UserJobs" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.jobTitles") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Customer/PositionMapping" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.positionMapping") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Customer/PositionMappingApprove" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.waitingPositionMapping") }}</span>
              </a>
            </li>
          </router-link>

          <!--  -->

          <router-link to="/Customer/PositionReport" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.positionReport") }}</span>
              </a>
            </li>
          </router-link>

          <!--  -->
          <router-link to="/Customer/CustomerDashboard" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.dashboard") }}</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>


    <li class="menu-section">
      <h4 class="menu-text">Company setup</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/Customer') }" v-if="isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-user-1"></i>
        <span class="menu-text">Company setup</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Company setup</span>
            </span>
          </li>

          <router-link to="/Customer/Profile" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.profile") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Customer/UserJobs" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.jobTitles") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Customer/PositionMapping" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.positionMapping") }}</span>
              </a>
            </li>
          </router-link>



        </ul>
      </div>
    </li>



    <li class="menu-section" v-if="isCustomerUser">
      <h4 class="menu-text">Reports</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/Customer') }" v-if="isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-user-1"></i>
        <span class="menu-text">Reports</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Reports</span>
            </span>
          </li>



          <router-link to="/Customer/PositionReport" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.positionReport") }}</span>
              </a>
            </li>
          </router-link>

          <!--
-->
        </ul>
      </div>
    </li>

    <li class="menu-section" v-if="!isCustomerUser">
      <h4 class="menu-text">Upcomming features</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/Upcomming') }" v-if="!isCustomerUser">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-list-2"></i>
        <span class="menu-text">Upcomming features</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Upcomming features</span>
            </span>
          </li>
          <router-link to="/Customer/CustomerDashboard" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.dashboard") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/Customer/CustomerReports" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Benefits report</span>
              </a>
            </li>
          </router-link>
          <router-link to="/Customer/Benefits" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.benefits") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/Customer/CustomerDashboard" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.dashboard") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/Customer/ReportGenerator" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.reportGenerator") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/Customer/AnalysisReport" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]" v-if="!isCustomerUser">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("leftMenu.customer.analysisReport") }}</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "KTMenu",
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
    isCustomerUser() {
      return this.$store.getters.customer != "No Customer";
    },
  },
  data: () => ({
    items: [
      {
        icon: "view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        icon: "account",
        title: "user",
        to: "/pages/user",
      },
      {
        title: "rtables",
        icon: "clipboard-outline",
        to: "/tables/regular-tables",
      },
      {
        title: "typography",
        icon: "format-font",
        to: "/components/typography",
      },
      {
        title: "icons",
        icon: "chart-bubble",
        to: "/components/icons",
      },
      {
        title: "google",
        icon: "map-marker",
        to: "/maps/google-maps",
      },
      {
        title: "notifications",
        icon: "mdi-bell",
        to: "/components/notifications",
      },
    ],
  }),
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    myAccFunc() {
      var x = document.getElementById("demoAcc");
      if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
      } else {
        x.className = x.className.replace(" w3-show", "");
      }
    },
    myAccFunc2() {
      var x = document.getElementById("demoAcc2");
      if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
      } else {
        x.className = x.className.replace(" w3-show", "");
      }
    },
    myAccFunc3() {
      var x = document.getElementById("subscriptionMenu");
      if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
      } else {
        x.className = x.className.replace(" w3-show", "");
      }
    },
    marketAnalysisClick() {
      var x = document.getElementById("marketAnalysisList");
      if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
      } else {
        x.className = x.className.replace(" w3-show", "");
      }
    },
    CustomerClick() {
      var x = document.getElementById("CustomerList");
      if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
      } else {
        x.className = x.className.replace(" w3-show", "");
      }
    },
  }
};
</script>
